import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const TradeTab = ({ tabDataBlog }) => {
  const [data, setData] = useState(
    document.querySelectorAll("#futuretrade_wrapper tbody tr")
  );
  const sort = 50;
  const activePag = useRef(0);
  const [test, settest] = useState(0);
  const [modalImageSrc, setModalImageSrc] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);

  const handleShowImageModal = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setShowImageModal(true);
  };

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };
  // use effect
  useEffect(() => {
    setData(document.querySelectorAll("#futuretrade_wrapper tbody tr"));
    //chackboxFun();
  }, [test]);

  // Active pagginarion
  activePag.current === 0 && chageData(0, sort);
  // paggination
  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active paggination & chage data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    settest(i);
  };
  return (
    <>
      <div className="table-responsive dataTabletrade ">
        <div id="futuretrade_wrapper" className="dataTables_wrapper no-footer">
          <table
            id="example"
            className="table display dataTable no-footer"
            style={{ minWidth: "845px" }}
          >
            <thead>
              <tr>
                <th>Date</th>
                <th>Message</th>
                <th>Quantity</th>
                <th>Realised PnL</th>
                <th className="text-end">Image</th>
              </tr>
            </thead>
            <tbody>
              {tabDataBlog.map((item, index) => (
                <tr key={index}>
                  <td>{item.date}</td>
                  <td>{item.message}</td>
                  <td>{item.quantity}</td>
                  <td>{item?.realizedPnl || 0}</td>
                  <td className="text-end">
                    <img
                      src={item.image || "https://picsum.photos/200/200"}
                      alt="thumbnail"
                      style={{
                        width: "50px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleShowImageModal(
                          item.image || "https://picsum.photos/200/300"
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-3">
            <div className="dataTables_info">
              Showing {activePag.current * sort + 1} to{" "}
              {data.length > (activePag.current + 1) * sort
                ? (activePag.current + 1) * sort
                : data.length}{" "}
              of {data.length} entries
            </div>
            <div
              className="dataTables_paginate paging_simple_numbers mb-0"
              id="application-tbl1_paginate"
            >
              <Link
                className="paginate_button previous "
                to="/"
                onClick={() =>
                  activePag.current > 0 && onClick(activePag.current - 1)
                }
              >
                <i className="fa fa-angle-double-left"></i>
              </Link>
              <span>
                {paggination.map((number, i) => (
                  <Link
                    key={i}
                    to="/"
                    className={`paginate_button  ${
                      activePag.current === i ? "current" : ""
                    } `}
                    onClick={() => onClick(i)}
                  >
                    {number}
                  </Link>
                ))}
              </span>

              <Link
                className="paginate_button next"
                to="/"
                onClick={() =>
                  activePag.current + 1 < paggination.length &&
                  onClick(activePag.current + 1)
                }
              >
                <i className="fa fa-angle-double-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showImageModal} onHide={() => setShowImageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={modalImageSrc} alt="Full Size" style={{ width: "100%" }} />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default TradeTab;
