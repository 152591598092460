import React, { useState, useEffect, useContext } from "react";
import ExchangeLineChart from "./Exchange/ExchangeLineChart";
import { SocketContext } from "../../../context/WebSocketContext"; // Import your SocketContext

const AIParams = () => {
  const socket = useContext(SocketContext);
  const [numbers, setNumbers] = useState(() => {
    // Try to load initial state from localStorage
    const savedNumbers = localStorage.getItem("plowdata");
    const parsed = JSON.parse(savedNumbers);
    return savedNumbers ? parsed.params : [];
  });

  useEffect(() => {
    // changeBackground({ value: "dark", label: "Dark" });

    // Listen for metrics data from socket
    socket.on("metrics", (data) => {
      const { params } = data;
      setNumbers(params);
      localStorage.setItem("plowdata", JSON.stringify(data)); // Save new data to localStorage
    });

    return () => {
      socket.off("metrics");
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional, adds a smooth scrolling effect
    });
  };

  const generateChartGroupData = (parameters, numbers) => {
    const chartData = parameters.map((param, i) => ({
      title: param,
      number:
        numbers && numbers[i] !== undefined
          ? `${numbers[i].toFixed(2)}`
          : "N/A", // Check if numbers array exists and if the value at index i exists
      icon: Math.random() > 0.5 ? "up" : "down",
      chartStatus: <ExchangeLineChart />,
    }));
    return chartData;
  };

  // Array of parameters
  const parameters = [
    "TrueFX",
    "Quandl",
    "RSI",
    "MACD",
    "Bollinger Bands",
    "Volume",
    "Sentiment scores",
    "ATR (Average True Range)",
    "Fibonacci Retracements",
    "Pivot Points",
    "Price Action Patterns",
    "Candlestick Patterns",
    "Market Profile",
    "Normalization",
    "Standardization",
    "Outlier Removal",
    "Missing Value Imputation",
    "Feature Scaling",
    "Consistency",
    "Accuracy",
    "Completeness",
    "Timeliness",
    "Validity",
    "Uniqueness",
    "API Aggregation",
    "ETL Process",
    "Streaming Data Pipeline",
    "Batch Data Pipeline",
    "Unsupervised Learning Clusters",
    "Anomaly Detection Tags",
    "Training Set",
    "Validation Set",
    "Test Set",
    "Synthetic Data Generation",
    "Data Bootstrapping",
    "SMOTE",
    "Time Series Plots",
    "Correlation Matrix",
    "Feature Importance",
    "Transformer",
    "Gated Recurrent Unit (GRU)",
    "Deep Q-Network (DQN)",
    "Generative Adversarial Network (GAN)",
    "Variational Autoencoder (VAE)",
    "Residual Network (ResNet)",
    "Attention Mechanism",
    "Ensemble Learning",
    "Reinforcement Learning with Policy Gradient",
    "Long Short-Term Memory (LSTM)",
    "Autoencoder",
    "Decision Tree",
    "Random Forest",
    "Gradient Boosting Machine (GBM)",
    "Support Vector Machine (SVM)",
    "Logistic Regression",
    "K-Nearest Neighbors (KNN)",
    "Naive Bayes",
    "Extreme Gradient Boosting (XGBoost)",
    "AdaBoost",
    "Bayesian Neural Network",
    "Echo State Network (ESN)",
    "Restricted Boltzmann Machine (RBM)",
    "Deep Belief Network (DBN)",
    "Capsule Network",
    "Siamese Network",
    "Self-Organizing Map (SOM)",
    "Grid Search",
    "Random Search",
    "Bayesian Optimization",
    "Genetic Algorithms",
    "Hyperband",
    "Population-based Training (PBT)",
    "Tree-structured Parzen Estimator (TPE)",
    "Sequential Model-based Optimization (SMBO)",
    "Bagging",
    "Boosting",
    "Stacking",
    "Voting",
    "Weighted Average",
    "Gradient-Boosted Decision Trees (GBDT)",
    "Meta-Learner",
    "Adaboost",
    "Rotation Forest",
    "XGBoost",
    "LightGBM",
    "CatBoost",
    "Sklearn Stacking",
    "L1 Regularization (Lasso)",
    "L2 Regularization (Ridge)",
    "Dropout",
    "Batch Normalization",
    "Early Stopping",
    "Data Augmentation",
    "Noise Injection",
    "Weight Decay",
    "Label Smoothing",
    "Mixup Augmentation",
    "CutMix Augmentation",
    "Knowledge Distillation",
  ];

  const chartGroup = generateChartGroupData(parameters, numbers);

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            {chartGroup.map((item, i) => (
              <div
                className="col-lg-6 col-xl-3 col-md-6"
                onClick={scrollToTop}
                key={i}
              >
                <div className="card overflow-hidden">
                  <div className="card-body py-0 pb-4 pt-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="fs-18 font-w400 mb-0">{item.title}</h4>
                      <div className="d-flex align-items-center">
                        <h2 className="count-num">{item.number}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AIParams;
