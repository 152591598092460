import React from "react";
import ReactApexChart from "react-apexcharts";

const AccountBalanceChart = ({ data }) => {
  const series = [
    {
      name: "Account Balance",
      data: data.map((item) => ({
        x: new Date(item.timestamp),
        y: item.accountBalance,
      })),
    },
  ];

  const options = {
    chart: { type: "line", height: 350 },
    xaxis: { type: "datetime" },
    yaxis: {
      labels: {
        formatter: (value) => "$" + value.toFixed(2),
      },
    },
    tooltip: { x: { format: "dd MMM yyyy HH:mm:ss" } },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={350}
    />
  );
};

export default AccountBalanceChart;
