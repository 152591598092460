import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

import { ThemeContext } from "../../../context/ThemeContext";

import modal from "../../../images/illustration/modal.png";

const ResetModal = () => {
  const navigate = useNavigate();

  const { changeBackground } = useContext(ThemeContext);

  const [showSlideUp, setShowSlideUp] = useState(false);

  // use effects

  // this makes sure we use the dark theme
  useEffect(() => {
    changeBackground({ value: "dark", label: "Dark" });
  }, []);

  // this is used to pop up a modal to tell the user login
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowSlideUp(true);
    }, 3_600_000);

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <div className="row">
        <Modal
          className="fade bd-example-modal-lg"
          size="lg"
          animation
          show={showSlideUp}
        >
          <Modal.Header>
            <Modal.Title> Supercharge Your AI Trading Experience!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="row justify-content-center h-100 align-items-center "
              id="modal-content"
            >
              <h2>Unlock the Full Potential of AI Trading</h2>
              <img
                style={{ width: "50%" }}
                src={modal}
                className="img-fluid"
                alt="AI Trading"
              />
              <p>
                We're thrilled that you're interested in our AI trading
                platform. To take your trading journey to the next level and
                make the most out of your experience, consider upgrading to our
                premium service.
              </p>

              <div className="py-3 benefits">
                <h3>Why Go Premium?</h3>
                <ul>
                  <li>
                    <strong>Unlimited Access:</strong> Dive deep into real-time
                    trades 24/7
                  </li>
                  <li>
                    <strong>Comprehensive Insights:</strong> Access complete
                    trade history and performance metrics
                  </li>
                  <li>
                    <strong>Advanced Analysis Tools:</strong> Utilize
                    cutting-edge tools for precise decision-making
                  </li>
                  <li>
                    <strong>Premium Support:</strong> Get priority assistance
                    from our dedicated support team
                  </li>
                </ul>
              </div>

              <div className="py-3 subscription-options">
                <h3>Subscription Choices:</h3>
                <ul>
                  <li>
                    <strong>Free Access:</strong> Enjoy 5 minutes of
                    complimentary access
                  </li>
                  <li>
                    <strong>Premium Access:</strong> Unlock limitless
                    opportunities
                  </li>
                </ul>
              </div>

              {/* <div class="cta-buttons">
                <a href="#signup-page" class="btn btn-primary">
                  Sign Up Now
                </a>
                <a href="#pricing-page" class="btn btn-secondary">
                  Explore Pricing
                </a>
              </div> */}

              <p>
                Your financial success matters to us, and we're here to help you
                every step of the way. Don't miss out on this chance to
                supercharge your trading strategy with premium access!
              </p>

              <p>
                Already part of our community?{" "}
                <a href="#login-page">Log in here</a> to elevate your trading
                game instantly.
              </p>

              <p>
                Rest assured, your information is safe with us. We respect your
                privacy and won't bombard you with unwanted emails.
              </p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => navigate("/register")} variant="primary">
              Sign Up Now
            </Button>
            <Button onClick={() => navigate("/pricing")} variant="primary">
              Explore Pricing
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* </div> */}
    </>
  );
};

export default ResetModal;
